import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./style.module.scss";

import InsuranceOffer from "../insuranceOffer";
import { affiliateOfferZones } from "@lib/shared/config";
import Widget from "./widget";
import OffersColumn from "./widget/offersColumn";
import Offer from "@components/shared/offer";
import { getWidgetType, extractWidgets } from "@lib/sharedUtils";
import MortgageOffer from "../mortgageOffer";
import { useBingEnhancedConversion } from "@hooks/useBingEnhancedConversion";
import { cloneDeep } from "lodash";

interface FieldsState {
    [x: string]: string;
}
export const decodeFieldsFromText = (
    text: string,
    fieldsState: FieldsState | null,
) => {
    let clonedText = text;

    if (!fieldsState) return clonedText;

    const dynamicStrings = text?.match(/\${([^}]*)}/g);

    dynamicStrings?.forEach((item) => {
        if (fieldsState[item.replace("${", "").replace("}", "")]) {
            const temp = clonedText.replace(
                item,
                fieldsState[item.replace("${", "").replace("}", "")],
            );
            clonedText = temp;
        }
    });

    return clonedText;
};

export default function ThankYou({
    insuranceListingHeader,
    customThankYouMessage,
    hideListingTitle,
    inForm = false,
    isPageBuilder = false,
    className,
}: {
    insuranceListingHeader?: string;
    customThankYouMessage?: ReactNode;
    hideListingTitle?: boolean;
    inForm?: boolean;
    isPageBuilder?: boolean;
    className?: string;
}): ReactElement {
    const {
        form,
        clicksOffers,
        visitDetails,
        thankYouOffers,
        thankyouListing,
    } = useDomainContext();
    const [successMessageArray, setSuccessMessageArray] = useState<string[]>(
        [],
    );
    const [widgets, setWidgets] = useState<string[] | null>([]);

    // const stickyOffer = useMemo(() => {
    //     if (thankYouOffers && thankYouOffers.length) {
    //         return thankYouOffers.find((offer) => offer.sticky);
    //     }
    //     return null;
    // }, [thankYouOffers]);

    useEffect(() => {
        const fieldsData = JSON.parse(
            localStorage.getItem("dynamicFields") as string,
        ) as FieldsState | null;
        const formAnswers = JSON.parse(
            localStorage.getItem("globalFormValues") as string,
        ) as FieldsState | null;
        // @ts-ignore
        window.formAnswers = cloneDeep(formAnswers);

        const [widgets, successMessageArrayAfterSplitting] = extractWidgets(
            form ? decodeFieldsFromText(form.successMessage, fieldsData) : "",
        );
        setWidgets(widgets ? widgets : null);
        setSuccessMessageArray(successMessageArrayAfterSplitting as string[]);
    }, []);

    useBingEnhancedConversion();

    if (form || customThankYouMessage) {
        return (
            <>
                <div
                    className={`${styles["thank-you-page"]} ${
                        inForm ? "" : `min-h-[85vh]`
                    } pb-10`}
                >
                    <div className="">
                        {customThankYouMessage ? (
                            <div id="thank-you-page" className={className}>
                                {customThankYouMessage}
                            </div>
                        ) : (
                            <div
                                className={
                                    thankYouOffers &&
                                    thankYouOffers.length &&
                                    (!widgets || widgets.length === 0) &&
                                    !inForm
                                        ? "flex justify-center items-start flex-col md:flex-row"
                                        : ""
                                }
                            >
                                <div
                                    id="thank-you-page"
                                    className={
                                        (thankYouOffers &&
                                        thankYouOffers.length &&
                                        (!widgets || widgets.length === 0) &&
                                        !inForm
                                            ? "w-full md:w-2/4 lg:w-2/3"
                                            : "") + ` ${className ?? ""}`
                                    }
                                >
                                    {successMessageArray.map(
                                        (content, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: content,
                                                        }}
                                                    ></div>
                                                    {widgets?.[index] ? (
                                                        <Widget
                                                            type={getWidgetType(
                                                                widgets[index],
                                                            )}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                                {!inForm &&
                                (!widgets || widgets.length === 0) ? (
                                    <OffersColumn />
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>
                    {thankyouListing &&
                    thankyouListing?.offers?.length &&
                    !inForm ? (
                        <div
                            className={`w-full pt-6 mt-6`}
                            style={{ background: "#fbfbfb" }}
                        >
                            {thankyouListing.pageTitle && !hideListingTitle && (
                                <h2 className="text-xl md:text-2xl text-center md:text-left px-4 md:px-6 font-bold">
                                    {thankyouListing.pageTitle}
                                </h2>
                            )}
                            {thankyouListing?.offers?.map((offer, index) => (
                                <Offer
                                    zone={affiliateOfferZones.THANK_YOU}
                                    offer={offer}
                                    key={index}
                                    className={`mt-0 ${styles["offersList"]}`}
                                    style={{
                                        borderStyle: "bottom",
                                        background: "transparent",
                                        borderColor: "#eee",
                                        buttonBackground: "#3EA636",
                                        buttonForground: "#fff",
                                    }}
                                />
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}

                    {!inForm &&
                    !isPageBuilder &&
                    insuranceListingHeader &&
                    clicksOffers &&
                    clicksOffers.length &&
                    !clicksOffers[0].isMortgage ? (
                        <h2 className="my-4 pt-10 font-bold text-center text-lg md:text-xl">
                            {insuranceListingHeader}
                        </h2>
                    ) : (
                        <></>
                    )}

                    {clicksOffers && !inForm && !isPageBuilder && (
                        <div className="mt-10 md:mt-5 w-full">
                            {clicksOffers
                                // ?.slice(0, 2)
                                ?.map((clickOffer, index) =>
                                    !clickOffer.isMortgage ? (
                                        <InsuranceOffer
                                            key={index}
                                            index={index}
                                            item={clickOffer}
                                            category="Auto Insurance"
                                            state={visitDetails?.region}
                                            primaryColor="#f79626"
                                            textColor="#266199"
                                            btnLabel="I Want That"
                                        />
                                    ) : (
                                        <MortgageOffer
                                            key={index}
                                            // index={index}
                                            offer={clickOffer}
                                        />
                                    ),
                                )}
                        </div>
                    )}
                </div>
            </>
        );
    }

    return <></>;
}
